<template>
  <div>
    <v-chip
      v-for="(item, index) in schedule"
      :key="index"
      class="ma-2"
      :close="onChange !== null"
      color="primary"
      text-color="white"
      small
      @click="() => handleEditTime(index)"
      @click:close="handleCloseTag(item)"
      style="margin:0px;"
    >
      {{ getTimeFormated(item) }}
    </v-chip>
    <v-dialog v-model="dialogEditTime" persistent max-width="250">
      <v-card>
        <center>
            <interval-times
              v-model="timeEdited"
              style="padding-left:60px;padding-top:30px;"
            />
        </center>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="handleEditKO">{{$t('common.cancel')}}</v-btn>
          <v-btn color="green darken-1" text @click="() => handleEditOK(index)">{{$t('common.ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import IntervalTimes from '@/components/intervalTimes/Index'
  import utils from '@/services/utils'
  export default {
    components: {
      IntervalTimes
    },
    props: {
      index: {
        type: Number,
        required: true
      },
      data: {
        type: Array,
        required: false,
        defaul: []
      },
      onChange: {
        type: Function,
        required: false,
        default: null
      }
    },
    data () {
      return {
        actualIndex: null,
        dialogEditTime: false,
        timeEdited: null,
        schedule: null
      }
    },
    computed: {
    },
    watch: {
      data (v) {
        this.schedule = v
      }
    },
    mounted() {
      this.schedule = this.data
    },
    methods: {
      getTimeFormated (value) {
        return `${utils.pad(value.hours)}:${utils.pad(value.minutes)}`
      },
      handleCloseTag (value) {
        this.schedule = this.schedule.filter (x => x !== value)
        if (this.onChange !== null) this.onChange(this.schedule, this.index)
      },
      handleEditTime (value) {
        this.actualIndex = value
        this.timeEdited = (this.schedule[value].hours * 60) + this.schedule[value].minutes
        this.dialogEditTime = true
      },
      handleEditOK () {
        this.schedule[this.actualIndex] = utils.convertMinutesToTime(this.timeEdited)
        this.timeEdited = null
        this.dialogEditTime = false
        this.actualIndex = null
      },
      handleEditKO () {
        this.timeEdited = null
        this.dialogEditTime = false
        this.actualIndex = null
      }
    }
  }
</script>

