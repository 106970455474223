<template>
  <v-layout >
    <table class="timeline">
      <tr
        v-for="(item, index) in timetable"
        :key="`rowSchedule${index}`"
      >
        <td class="timeline-day">
          <span :style="{'color': index == 0 ? '#F4433699' : '' }">{{$t(`common.dayOfWeek.${index}`).toUpperCase()}}</span>
          <custom-dialog
            :key="`force24${index}`"
            title="Superado el l�mite"
            message="El tramo supera las 24h, �quiere establecerlo a esa hora?"
            :index="index"
            :showDialog="showForce24"
            :onKO="() => { showForce24 = false }"
            :onOK="handleForce24"
          />
          <custom-dialog
            title="Superado el l�mite"
            message="El tramo no puede superar las 24h"
            :showDialog="showLimit24"
            :onKO="() => { showLimit24 = false }"          
          />
        </td>
        <td width="65%">
          <schedule-day
            :data="item"
            :index="parseInt(index)"
            :onChange="(data, i) => timetable[index] = data"
          />
        </td>
        <td class="timeline-action">
          <v-icon v-if="!isUniqueByDay || item.length === 0" @click="handleAddSection(item, index)" color="primary">
            mdi-plus
          </v-icon>
          <v-icon v-else style="opacity: 0.2" color="primary">mdi-plus</v-icon>
          <v-dialog 
            v-if="item.length > 0"
            v-model="copyDialog" persistent max-width="590"
          >
            <template v-slot:activator="{ on }">
               <v-icon v-on="on" style="margin-left:5px;" color="blue"
                @click="actualIndex=index"
               >
                mdi-content-copy
              </v-icon>                
            </template>
            <v-card>
              <v-card-text class="headline" style="padding-top: 15px;">D�as en los que copiar los valores
                <v-btn 
                  style="margin-right: 10px;"
                  color="primary"
                  text
                  small
                  @click="handleSelectAll"
                >
                  seleccionar todos
                </v-btn>
              </v-card-text>
              <center style="padding-top: 5px;padding-left: 10px;">
                <v-btn
                  v-for="(tt, copyDayIndex) in timetable"
                  :key="`copy${copyDayIndex}`"
                  style="margin-right: 10px;"
                  :color="copySelected.indexOf(copyDayIndex) >= 0 ? 'primary' : 'white'"
                  @click="handleSelectedCopy(copyDayIndex)"
                >
                  {{$t(`common.dayOfWeek.${copyDayIndex}`).substring(0, 3).toUpperCase()}}
                </v-btn>
              </center>
              <!-- <v-card-text>{{message}}</v-card-text> -->
              <v-card-actions>
                <v-spacer />
                <v-btn @click="handleCopyKO">{{$t('common.cancel')}}</v-btn>
                <v-btn color="primary" @click="handleCopyOK">{{$t('common.ok')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-icon 
            v-if="item.length > 0"
            @click="handleDeleteSection(index)" color="red">
            mdi-delete
          </v-icon>                                
        </td>
      </tr>
    </table>
  </v-layout>
</template>
<script>
import utils from '@/services/utils'
import CustomDialog from '@/components/CustomDialog'
import ScheduleDay from './ScheduleDay'
export default {
  components: {
    ScheduleDay,
    CustomDialog,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    isUniqueByDay: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timetable: null,
    copySelected: [],   // utilizado para el duplicar por semana
    copyDialog: false,  // utilizado para el duplicar por semana
    actualIndex: null,
    showForce24: false,
    showLimit24: false,
  }),
  watch: {
    'editedItem.Timetable' (v) {
      this.timetable = v
    }
  },
  mounted () {
    this.timetable = this.editedItem.Timetable
  },
  methods: {
    handleChangeTimeTable () {
      this.onChange(this.timetable)
    },
    handleAddSection (item, index) {       
      if (item.length > 0) {
        let aux = item[item.length - 1]
        let minutes = aux.minutes + (aux.hours * 60)
        minutes = minutes + this.editedItem.Interval
        if (minutes > 1440) {
          if (item.filter(x => x.hours === 24 && x.minutes === 0).length > 0) {
            this.showLimit24 = true
          } else {
            this.actualIndex = index
            this.showForce24 = true
          }            
        } else item.push(utils.convertMinutesToTime(minutes))    
      } else {
        item = []
        item.push(utils.convertMinutesToTime(this.editedItem.StartTime))
      }
      this.timetable[index] = item
      this.handleChangeTimeTable()
    },
    handleDeleteSection(index) {
      this.timetable[index] = []
      this.handleChangeTimeTable()
    },
    handleForce24() {
      if(this.actualIndex) {
        this.timetable[this.actualIndex].push(utils.convertMinutesToTime(1440))
        this.showForce24 = false
        this.actualIndex = null
        this.handleChangeTimeTable()
      }        
    },
    handleSelectAll () {
      this.copySelected = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ] 
      this.handleChangeTimeTable()
    },
    handleSelectedCopy (value) {
      if (this.copySelected.indexOf(value) >= 0)
        this.copySelected = this.copySelected.filter(x => x!== value)
      else
        this.copySelected.push(value)

      this.handleChangeTimeTable()
    },      
    handleCopyKO () {
      this.copySelected = []
      this.copyDialog = false
      this.actualIndex = null
    },
    handleCopyOK () {
      this.copySelected.forEach(item => {
        this.timetable[item] = JSON.parse(JSON.stringify(this.timetable[this.actualIndex]))
      })
      this.copySelected = []
      this.copyDialog = false
      this.actualIndex = null
      this.handleChangeTimeTable()
    }
  },
}
</script>
<style scoped>
.scheduleMain {
  padding: 5px;
  width: 100%;
}
.timeline {
  width: 100%;
}
.timeline tr {
  height: 40px;;
}
.timeline-day {
  font-weight: 700;
  width: 0px;
  text-align: right;
  color: #00000099;
}
.timeline-action {
  width: 80px;
}
</style>

