<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">      
      <v-card>
        <v-card-title class="headline">{{title}}</v-card-title>
        <v-card-text>{{message}}</v-card-text>
        <v-card-actions v-if="onOK">
          <v-spacer />
          <v-btn text @click="onKO">{{$t('common.cancel')}}</v-btn>
          <v-btn color="primary" @click="onOK">{{$t('common.ok')}}</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer />
          <v-btn color="primary" @click="onKO">{{$t('common.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: null
    },
    title: {
      type: String,
      requerido: false,
      default: null
    },
    message: {
      type: String,
      requerido: false,
      default: null
    },
    onKO: {
      type: Function,
      required: false,
      default: null
    },
    onOK: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
    }
  },
  watch: {
    showDialog (v) {
      this.dialog = v
    },
  },
  mounted() {
    if (this.showDialog !== null) this.dialog = this.showDialog
  },
}
</script>

