<template>
  <v-menu
    v-if="date"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="icon"
        v-bind="attrs"
        v-on="on"
      >
        {{icon}}
      </v-icon>
      <v-combobox
        v-else
        v-model="date"
        :label="$t(label, locale)"
        prepend-icon="mdi-calendar-multiselect"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        :dense="dense"
        hide-details
      />
    </template>
    <v-date-picker v-model="date" no-title scrollable :locale="locale" @input="onChangeDate" :max="max">
      <v-spacer v-if="!closeOnSelect" />
      <v-btn v-if="!closeOnSelect" text @click="menu = false">{{$t('common.cancel', locale)}}</v-btn>
      <v-btn v-if="!closeOnSelect" text color="primary" @click="handleChangeDate">{{$t('common.ok', locale)}}</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    max: {
      type: String,
      required: false,
      default: null
    },
    outlined: {
      type: Boolean,
      require: false,
      default: false
    },
    dense: {
      type: Boolean,
      require: false,
      default: false
    },
  },
  data: () => ({
    date: null, //['2018-09-15', '2018-09-20'],
    menu: false,
  }),
  computed: {
    ...mapState('app', ['locale']),
  },
  watch: {
    value (newValue) {
      this.date = newValue  
    }
  },
  mounted () {
    this.date = this.value
  },
  methods: {
    handleChangeDate () {
      this.$refs.menu.save(this.date)
      this.$emit('input', this.date)
    },
    onChangeDate () {
      if(this.closeOnSelect) {
        this.handleChangeDate()
        this.menu = false
      }
    }
  }
}
</script>

